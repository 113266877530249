/**
 * Home
 */

.home {
  @include media-query($on-small-phone) {
    width: 100%;
  }
}

/**
 * Site header
 */
.site-header {
  min-height : $spacing-unit * 1.865;
  padding-top: 20px;

  position        : sticky;
  top             : 0;
  background-color: white;
  z-index         : 1;
}

.site-title {
  @include relative-font-size(1);
  font-weight   : 100;
  line-height   : 1.8em;
  letter-spacing: 0.05em;
  margin-bottom : 0;
  float         : left;
}

.site-nav {
  float: right;

  .nav-trigger {
    display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {

    @include relative-font-size(0.85);
    line-height   : 1.8em;
    font-weight   : 300;
    letter-spacing: 0.05em;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @include media-query($on-palm) {
    position        : absolute;
    top             : $small-spacing-unit * 2.5;
    right           : $spacing-unit;
    background-color: $background-color;
    border          : 1px solid $grey-color-light;
    border-radius   : 5px;
    text-align      : center;

    label[for="nav-trigger"] {
      display: block;
      float  : right;
      width  : 36px;
      height : 36px;
      z-index: 2;
      cursor : pointer;
    }

    .menu-icon {
      display    : block;
      float      : right;
      width      : 36px;
      height     : 26px;
      line-height: 0;
      padding-top: 10px;
      text-align : center;

      >svg path {
        fill: $grey-color-dark;
      }
    }

    input~.trigger {
      clear  : both;
      display: none;
    }

    input:checked~.trigger {
      display       : block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }

      margin-left: 20px;

      @include media-query($on-palm) {
        margin-left: 0;
      }
    }
  }
}

.lightgrey-on-hover:hover {
  color: #828282;
}

/**
 * Site footer
 */

.wrapper {
  display        : flex;
  justify-content: space-between;
}

.site-footer {
  margin-top: $spacing-unit;

  .wrapper {
    @include media-query($on-small-phone) {
      flex-direction: column;
      align-items   : center;

      p {
        text-align: center;
        padding   : $small-spacing-unit 0;
      }
    }
  }
}

.footer-social-icons {
  display: flex;
}

.footer-icon-img {
  width        : 25px;
  height       : 25px;
  padding-right: $small-spacing-unit;
}

.footer-copyright {
  display: flex;
}

.footer-copyright-text {
  font-size  : 16px;
  font-weight: 100;
}

/**
 * Homepage intro
 */

.intro {
  margin-top   : $small-spacing-unit * 14;
  margin-bottom: $small-spacing-unit * 18;
  width        : 75%;

  @include media-query($flower-cutoff) {
    margin-top   : $spacing-unit * 0.5;
    margin-bottom: $spacing-unit;
    width        : 100%;
  }
}

.primary-hero-text-top {
  @include hero-text-top;
  margin-bottom: 0;
  line-height  : 1.4em;
}

.primary-hero-text-bottom {
  @include hero-text;
  line-height  : 1.4em;
  margin-bottom: 20px;
}

.hero-subtext {
  @include slim-tall-text;
  @include relative-font-size(1.19);
  line-height  : 1.6em;
  margin-bottom: 0;
}

.hero-subtext-width {
  width: 75%;

  @include media-query($on-palm) {
    width: 90%;
  }
}

.index-flower-div-wrapper {
  position: relative;
}

.index-flower-div {
  position: absolute;
  top     : -155px;
  right   : -238px;
  z-index : -5;
  width   : 395px;
  height  : auto;
}

/**
 * Homepage post previews
 */


.post {
  @include media-query($on-small-phone) {
    width: 100%;

    img {
      width: 100%;
    }
  }
}

.post-preview {
  display                   : flex;
  justify-content           : space-between;
  // margin                 : ($small-spacing-unit * 5) 0;
  // background-color       : #b5cfd8;
  // padding-right          : $spacing-unit * 2;
  margin-bottom             : $spacing-unit * 3;

  button {
    width        : 180px;
    height       : 50px;
    border-radius: 5px;
    font-size    : 16px;
    font-weight  : 400;
    background   : lightgray;
    border       : none;
    color        : black;
    padding      : 10px;

  }


  img {
    transition   : transform 0.2s;
    display      : block;
    // max-height: 380px;
    max-width    : 600px;
    width        : auto;
    height       : auto;
  }

  .post-link {
    display       : flex;
    flex-direction: column;
    @include relative-font-size(1.5);
  }

  .post-date {
    display      : flex;
    align-items  : flex-end;
    padding-right: $small-spacing-unit;
  }

  .post-meta {
    font-size: $base-font-size;
    color    : $grey-color;

  }

  @include media-query($on-palm) {
    display       : flex;
    flex-direction: column-reverse;
    align-items   : center;
  }
}

.post-preview:hover {
  img {
    transform: scale(1.05);
  }

  .post-preview-left {
    p {
      // color: $interaction-text-color;
    }

  }
}

.post-preview-right {
  background-color: rgba(200, 231, 237, 0.3);

  @include media-query($on-palm) {
    margin-bottom: $small-spacing-unit * 2;
  }

  @include media-query($on-small-phone) {
    width: 100%;

    img {
      width: 100%;
    }
  }

}

.post-preview-left {
  display        : flex;
  flex-direction : column;
  justify-content: center;
  margin         : 0 ($small-spacing-unit * 2.5) 0 0;

  @include media-query($on-palm) {
    margin-bottom: $small-spacing-unit * 2;
  }

  .view-case-study-link {
    @include relative-font-size(1.0);
    font-weight: 300;

    &:hover {
      text-decoration: underline;
    }
  }


  // Used for InterviewQ.
  .turquoise-case-study-link-color {
    color      : #202124;
    font-weight: 400;
  }

  // Used for ReadEasy.
  .pink-case-study-link-color {
    color      : #202124;
    font-weight: 400;
  }


}

.project-preview-hero-text {
  @include relative-font-size(1.8);
  font-weight  : bold;
  margin-bottom: $small-spacing-unit * 2.4;
}

.project-preview-hero-subtext {
  @include slim-tall-text;
  @include relative-font-size(0.8);
  line-height   : 1.4em;
  font-weight   : 400;
  margin-bottom : $small-spacing-unit * 0;
  letter-spacing: .02em;
}

.project-preview-subtext {
  @include slim-tall-text;
  @include relative-font-size(1.0);
  line-height  : 1.6em;
  margin-bottom: $small-spacing-unit * 3;
  width        : 85%;
}

/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style : none;

  >li {
    margin-bottom: $small-spacing-unit;
  }
}

/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height   : 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content-wrapper {
  display        : flex;
  justify-content: center;
}

.post-content {
  width: 60%;

  @include media-query($on-palm) {
    width: 95%;
  }

  margin-bottom: $spacing-unit;
  @include relative-font-size(1.1);

  h1,
  h2,
  h3,
  h4 {
    font-weight  : 600;
    color        : #202124;
    margin-top   : $small-spacing-unit * 5;
    margin-bottom: $small-spacing-unit * 1.3;
  }

  h1,
  h3,
  h4 {
    @include relative-font-size(1.18);
  }

  h2 {
    @include relative-font-size(0.9);
    color: #5F6368;
  }

  p,
  li {
    color: #3C4043;
    @include slim-tall-text;
    @include relative-font-size(1);
  }

  .interviewQ-highlight {
    background-color: #BBE2FF;//rgba(200, 231, 237, 0.3);
    box-shadow      : 0 ($small-spacing-unit * 1.2) 0 0 #fff inset;
  }

  .readeasy-highlight {
    background-color: #FFD1D4;
    box-shadow      : 0 ($small-spacing-unit * 1.2) 0 0 #fff inset;
  }

  img {
    @include margin-top-and-bottom;
  }
}

.picture-by-text-content {
  display        : flex;
  justify-content: center;
  width          : 100%;

  .text-content {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    width          : 50%;

    @include media-query($on-small-phone) {
      width: 95%;
    }
  }

  .space-right {
    margin-right: $small-spacing-unit * 2;

    @include media-query($on-small-phone) {
      margin-right: 0;
    }
  }

  .space-left {
    margin-left: $small-spacing-unit * 2;

    @include media-query($on-small-phone) {
      margin-left: 0;
    }
  }

  @include media-query($on-small-phone) {
    flex-direction: column;
  }


}

.img-with-caption-wrapper {

  display        : flex;
  justify-content: center;
  flex-direction : column;
  width          : 100%;

  .img-caption {
    display        : flex;
    justify-content: center;
    font-style     : italic;
    margin-top     : -$small-spacing-unit * 2;
    color          : #5F6368;
    @include relative-font-size(0.9);

  }
}

.lightbulb-list {
  list-style-type: none;

  li:before {
    content    : '\1F4A1';
    margin-left: -20px;
  }
}

.reverse-on-small {
  @include media-query($on-small-phone) {
    flex-direction: column-reverse;
  }
}

.final-prototype-button-wrapper {
  display        : flex;
  justify-content: center;
  margin         : $spacing-unit;
}

.final-prototype-button {
  width        : 250px;
  height       : 50px;
  border-radius: 30px;
  font-size    : 16px;
  font-weight  : 400;
  background   : #5C9BEF;
  border       : none;
  color        : white;
  padding      : 10px;
}

.final-prototype {
  display        : flex;
  justify-content: center;

  iframe {
    width : 438px;
    height: 930px;
  }
}

.case-study-strong-text {
  font-weight: 500;
}

.case-study-headliner-text {
  line-height   : 1.8em;
  letter-spacing: 0.06em;
  text-align    : left;
  font-size     : 18px;
  font-weight   : 100;
}


.small-image {
  width: 300px;
}

.inter-study-nav {
  display        : flex;
  justify-content: space-between;
  padding-top    : $spacing-unit;

  a {
    color: #3C4043;

    // &:hover {
    //   color: $interaction-text-color;
    // }

    font-weight: 100;
    line-height: 1.8em;
    font-size  : 16px;
  }
}

// DEPRECATED
.medium-centered-image {
  display        : flex;
  justify-content: center;
  @include margin-top-and-bottom;

  img {
    width: 70%;
  }
}

.small-centered-image {
  display        : flex;
  justify-content: center;
  @include margin-top-and-bottom;

  img {
    width: 50%;
  }
}


/**
 * About page
 */

.about-page-content {
  display       : flex;
  flex-direction: column;
  align-items   : center;
  margin-top    : $small-spacing-unit * 4;

  @include media-query($on-palm) {
    margin-top: 0;
  }

  h2 {
    @include relative-font-size(1.4);

    @include media-query($on-laptop) {
      @include relative-font-size(1.25);
    }
  }
}

.about-page-top {
  display: flex;
  width  : 90%;

  @include media-query($on-palm) {
    flex-direction: column;
    align-items   : center;

    img {
      max-width    : 400px;
      margin-bottom: $spacing-unit;
    }
  }
}

.about-page-right {
  margin-top     : $small-spacing-unit * 2.5;
  margin-left    : $spacing-unit;
  // margin-right: $spacing-unit * 1.5;
  @include slim-tall-text;

  @include media-query($on-palm) {

    margin-left: 0;
  }
}

.about-page-left {
  margin-top: $spacing-unit;

  img {
    max-width : 370px;
    max-height: 480px;

    @include media-query($on-small-phone) {
      max-width: 100%;
    }
  }

  @include media-query($on-palm) {
    margin-top: 0;
  }
}

.about-page-bottom {
  display        : flex;
  justify-content: center;
  width          : 90%;

  p {
    color: $text-color;
  }

  margin-top : $spacing-unit * 2;
}

.about-flower-div-wrapper {
  position: relative;
}

.about-flower-div {
  position: absolute;
  top     : -100px;
  left    : -100px;
  z-index : 0;
}

/**
 * Flower art
 */

.flower-art {
  @include media-query($flower-cutoff) {
    display: none;
  }
}