@charset "utf-8";

// Define defaults for each variable.



// $base-font-family: "Roboto Light",
$base-font-family: "Manrope", "Helvetica",
sans-serif !default;
$accent-font-family: "Playfair Display",
serif !default;
$base-font-size  : 16px !default;
$base-font-weight: 400 !default;
$small-font-size : $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit      : 30px !default;
$small-spacing-unit: 10px !default;

$text-color            : #202124 !default;
$accent-text-color     : #5c5c5c !default;
$background-color      : #ffffff !default;
$brand-color           : #000000 !default;
$interaction-text-color: tomato !default;

$grey-color      : #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark : darken($grey-color, 25%) !default;

$blue-color-light: #c0deed !default;

$table-text-align: left !default;

// Width of the content area
$content-width: 1020px !default;

$on-palm       : 750px !default;
$on-small-phone: 500px !default;
$on-laptop     : 750px !default;
$flower-cutoff : 1000px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left : $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

@mixin slim-tall-text {
  font-weight   : 100;
  // TODO(conor): parameterize line height
  line-height   : 1.6em;
}

@mixin margin-top-and-bottom {
  margin-top   : $spacing-unit;
  margin-bottom: $spacing-unit;
}

@mixin hero-text {
  @include relative-font-size(2.0);
  line-height: 1.3em;
  font-weight: bold;
}

@mixin hero-text-top {
  @include relative-font-size(3.0);
  line-height: 1.3em;
  font-weight: bold;
}

// Import partials.
@import "minima/base",
"minima/layout",
"minima/syntax-highlighting"
;